<template>
    <div class="on-site-room">
        <CheckoutLightbox
            :orderId="checkout.orderId"
            :submitType="checkout.submitType"
            :bedId="checkout.bedId"
            openBy="onSiteRoom"
        />
        <ChangeMemberListLightbox 
            v-model:isShow="memberList.isShow" 
            :type="memberList.type" 
        />
        <RoomRightLightbox 
            v-model:isShow="appointment.isShow" 
            :id="appointment.id"
            :name="appointment.name"
            :currentEndTime="appointment.currentEndTime"
        />
        <div class="top">
            <Logo />
            <TopTab />
        </div>
        <div class="index-container">
            <div class="title d-none d-xxl-block">現場房況</div>
            <transition appear name="fade" v-if="isListRequest">
                <NoData
                    v-show="
                        !roomList.data.roomList.length &&
                        !memberWorkList.data.reserve_list.length &&
                        !memberWorkList.data.service_list.length
                    "
                />
            </transition>
            <transition appear name="fade" v-if="isListRequest">
                <div
                    class="row"
                    v-show="
                        roomList.data.roomList.length ||
                        memberWorkList.data.reserve_list.length ||
                        memberWorkList.data.service_list.length
                    "
                >
                    <div class="room-wrap position-relative">
                        <div class="order-title">現場房況</div>
                        <div class="add-room" @click="passId2CheckoutLightbox">
                            <div class="img-wrap">
                                <img src="@/assets/tgd/reserve/add-btn.svg" alt="" />
                            </div>
                        </div>
                        <div class="row order-list-wrap">
                            <div
                                class="col-xxl-3 col-xl-6 mb-3"
                                v-for="room in roomList.data.roomList"
                                :key="room.room_id"
                            >
                                <div
                                    class="room-item"
                                    :class="{
                                        'room-reserve': room.end_time,
                                        'room-active': room.diffMinute != 0 && room.diffMinute < 10,
                                    }"
                                >
                                    <div class="active-icon-wrap" v-show="room.diffMinute != 0 && room.diffMinute < 10">
                                        <img src="@/assets/tgd/reserve/active-icon.svg" alt="" />
                                    </div>
                                    <div class="room-title">
                                        <span> 房間 : {{ room.room_name }} </span>
                                        <span>{{ room.end_time }}</span>
                                    </div>
                                    <div class="room-body">
                                        <div
                                            class="d-flex justify-content-between flex-wrap no-gutters py-1"
                                            v-for="bed_name in room.room_bed_name"
                                            :key="bed_name"
                                        >
                                            <div class="flex-grow-1">
                                                {{ bed_name.name }}
                                            </div>
                                            <div class="time-color flex-grow-1">
                                                {{ bed_name.end_time }}
                                            </div>
                                            <div class="d-flex flex-grow-1">
                                                <template v-if="room.end_time">
                                                    <div class="img-wrap" v-show="bed_name.room_service">
                                                        <a
                                                            href="javascript:;"
                                                            data-bs-toggle="tooltip"
                                                            :title="bed_name.room_service"
                                                        >
                                                            <img src="@/assets/tgd/reserve/content.svg" alt="" />
                                                        </a>
                                                    </div>
                                                    <div class="img-wrap" v-show="bed_name.payment_status == '1'">
                                                        <img src="@/assets/tgd/reserve/pay.svg" alt="" />
                                                    </div>
                                                </template>
                                                <div class="btn-icon" v-show="bed_name.service_type == 1">老</div>
                                            </div>
                                            <div class="flex-grow-1">
                                                <template v-if="bed_name.members_name">
                                                    <span class="btn ms-xl-auto me-xl-0">
                                                        {{ bed_name.members_name }}
                                                    </span>
                                                </template>
                                                <template v-else>
                                                    <button
                                                        class="btn btn-gold"
                                                        @click="passId2CheckoutLightbox(bed_name.bed_id)"
                                                    >
                                                        上鐘
                                                    </button>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="side-info-wrap">
                        <div class="staff-show-wrap">
                            <div class="staff-header">
                                <div class="staff-title">
                                    <img src="@/assets/tgd/reserve/people.svg" alt="" />
                                    <span>服務中</span>
                                </div>                             
                                <span class="before-date">結束時間</span>
                                <span class="next-status">狀態</span>
                                <span class="end-time">下班時間</span>
                                <span class="hour-time">班數</span>
                                <span class="sign-in-8">簽八</span>
                                <button 
                                    class="edit-btn" 
                                    type="button" 
                                    @click="memberList.isShow = true; memberList.type = 'service_list';"
                                >
                                    編輯
                                </button>
                            </div>
                            <div class="staff-body">
                                <div class="text-wrap">
                                    <p v-for="item in memberWorkList.data.service_list" :key="item.member_id">
                                        <span class="name">
                                            <b-button variant="link" @click="openAppointment(item, 'service')">
                                                {{ item.member_name }}
                                            </b-button>
                                        </span>
                                        <span class="before-date">{{ item.end_time }}</span>
                                        <span class="next-status translate-t-2">
                                            <p 
                                                :style="{'background-color': item.color}"
                                            >
                                                {{item.reservie_min_time}}
                                            </p>
                                        </span>
                                        <span class="end-time">{{ item.scheduled_end_time }}</span>
                                        <span class="hour-time">{{ item.class_num_eight }}</span>
                                        <span class="sign-in-8">
                                            <i v-if="item.sign_eight == 1" class="icon icon-check"></i>
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div class="staff-header">
                                <div class="staff-title">
                                    <img src="@/assets/tgd/reserve/time.svg" alt="" />
                                    <span>待班中</span>
                                </div>
                                <span class="before-date">前一班<br />結束時間</span>
                                <span class="next-status">狀態</span>
                                <span class="end-time">下班時間</span>
                                <span class="hour-time">班數</span>
                                <span class="sign-in-8">簽八</span>
                                <button 
                                    class="edit-btn" 
                                    type="button" 
                                    @click="memberList.isShow = true; memberList.type = 'waiting_list';"
                                >編輯</button>
                            </div>
                            <div class="staff-body">
                                <div class="text-wrap">
                                    <p v-for="(item, index) in memberWorkList.data.waiting_list" :key="index">
                                        <span class="name">
                                            <b-button variant="link" @click="openAppointment(item, 'waiting')">
                                                {{ item.member_name }}
                                            </b-button>
                                            <b-button
                                                v-if="item.is_button"
                                                class="yellow"
                                                variant="link"
                                                @click="cutInLine(item.member_id)"
                                            >
                                                <b-icon icon="arrow-up" />
                                            </b-button>
                                        </span>
                                        <span class="before-date">{{ item.before_date }}</span>
                                        <span class="next-status translate-t-2">
                                            <p 
                                                :style="{'background-color': item.color}"
                                            >
                                                {{item.reservie_min_time}}
                                            </p>
                                        </span>
                                        <span class="end-time">{{ item.end_time }}</span>
                                        <span class="hour-time">{{ item.class_num_eight }}</span>
                                        <span class="sign-in-8">
                                            <i v-if="item.sign_eight == 1" class="icon icon-check"></i>
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div class="staff-header">
                                <div class="staff-title">
                                    <img src="@/assets/tgd/reserve/date-icon.svg" alt="" />
                                    <span>預約</span>
                                </div>
                                <span class="reserve-people">人數</span>
                                <span class="reserve-date">預約時間</span>
                                <span class="reserve-aroma ">預排芳療師</span>
                            </div>
                            <div class="staff-body">
                                <div class="text-wrap">
                                    <p v-for="(item, index) in memberWorkList.data.reserve_list" :key="index">
                                        <span class="name" :key="`${img-wrap}-index`">
                                            {{ item.customer_name }}
                                            <div class="img-wrap" v-if="item.remark">
                                                <a
                                                    href="javascript:;"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-html="true"
                                                    :title="`${item.customer_name}<br>${item.remark}`"
                                                >
                                                    <img src="@/assets/tgd/reserve/reserve-more.svg" alt="" />
                                                </a>
                                            </div>
                                            <span v-else>{{ item.phone_four }}</span>
                                        </span>
                                        <span class="reserve-people">{{ item.reserve_num }}</span>
                                        <span class="reserve-date">{{ item.start_time }}</span>
                                        <span class="reserve-aroma d-flex flex-wrap">
                                            <template
                                                v-for="(val, i) in item.reserve_customer"
                                                :key="i"
                                            >
                                                <b-form-select
                                                    class="aroma-list"
                                                    v-model="val.member_code"
                                                    @change="changeAroma($event, val.reserve_detail_id)"
                                                    :style="{
                                                        'background-color': val.color
                                                    }"
                                                    :class="{
                                                        'aroma-wait': val.loading
                                                    }"
                                                    :disabled="val.member_code !== '' && val.color === '#ECE9E3'"
                                                >
                                                    <b-form-select-option :value="null" v-show="false">
                                                        {{val.member_code}}
                                                    </b-form-select-option>
                                                    <b-form-select-option :value="null">
                                                        不選擇
                                                    </b-form-select-option>
                                                    <b-form-select-option
                                                        v-for="v in rosterRecord.data"
                                                        :key="v.member_id"
                                                        :value="v.member_id"
                                                    >
                                                        {{ v.member_name }}{{ v.is_get_off_work}}
                                                    </b-form-select-option>
                                                </b-form-select>
                                            </template>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { reactive, nextTick, computed, ref, watch, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import useTooltip from '@/hooks/common/useTooltip';
import useHandleLightboxOpen from '@/hooks/common/useHandleLightboxOpen';
import { basePost } from '@/js/services/baseService';

import Logo from '@/components/includes/Logo.vue';
import TopTab from '@/components/includes/TopTab.vue';
import CheckoutLightbox from '@/components/lightbox/CheckoutLightbox.vue';
import NoData from '@/components/includes/NoData.vue';
import { flashMessage } from '@/js/swal';
import moment from 'moment';
import ChangeMemberListLightbox from '@/components/lightbox/ChangeMemberListLightbox.vue';
import RoomRightLightbox from '@/components/lightbox/RoomRightLightbox.vue';

export default {
    name: 'OnSiteRoom',
    components: {
        Logo,
        TopTab,
        CheckoutLightbox,
        ChangeMemberListLightbox,
        NoData,
        RoomRightLightbox
    },
    setup() {
        const { state } = useStore();
        const storeId = computed(() => state.auth.store_id);

        // 開燈箱
        const { openCheckoutLightbox } = useHandleLightboxOpen();
        const isShow = computed(() => state.global.isCheckoutLightboxShow);
        const checkout = reactive({
            orderId: '',
            submitType: '',
            bedId: 0,
        });

        const isListRequest = computed(() => {
            return (
                roomList.data.roomList.length ||
                memberWorkList.data.reserve_list.length ||
                memberWorkList.data.service_list.length
            );
        });

        // 現場房況列表
        let roomList = reactive({
            data: {
                roomList: [],
            },
        });
        //取得房況列表
        const getRoomList = async () => {
            try {
                const res = await basePost('/api_room/room_list', {
                    store_id: storeId.value,
                });
                const { status, data } = res;
                roomList.data = res.data.res_data;
                nextTick(() => {
                    useTooltip();
                });
            } catch (err) {
                console.error(err);
            }
        };
        getRoomList();

        // 芳療師服務狀況資訊
        let memberWorkList = reactive({
            data: {
                reserve_list: [],
                service_list: [],
            },
        });
        // 取得芳療師服務狀況資訊
        const getMemberWorkStatus = async () => {
            try {
                const res = await basePost('/api_room/member_condition', {
                    store_id: storeId.value,
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    const { res_data } = data;
                    memberWorkList.data = res_data
                }
                nextTick(() => {
                    useTooltip();
                });
            } catch (err) {
                console.error(err);
            }
            getRosterRecord();
        };
        getMemberWorkStatus();

        // 調整現場房況待班中芳療師順序
        const postMemberCutInLine = async (member_id) => {
            try {
                const res = await basePost('/api_room/member_condition_checked', {
                    store_id: storeId.value,
                    member_id,
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    flashMessage('修改成功');
                }
            } catch (err) {
                console.error(err);
            }
        };

        async function cutInLine(member_id) {
            await postMemberCutInLine(member_id);
            await getMemberWorkStatus();
        }

        const passId2CheckoutLightbox = (bed_id) => {
            checkout.orderId = 0;
            checkout.submitType = 0;
            checkout.bedId = isNaN(bed_id) ? '' : bed_id;
            openCheckoutLightbox();
        };

        watch(isShow, () => {
            if (!isShow.value) {
                checkout.orderId = -1;
                getRoomList();
                getMemberWorkStatus();
            }
        });

        // 服務中時間到 重取資料
        watch(
            () => memberWorkList.data.service_list,
            (service_list) => {
                const first = service_list[0];
                if (!first) return;
                changeTime.value = first.end_time;
            }
        );
        const changeTime = ref('');
        watch(changeTime, (end_time) => {
            const endTime = moment(end_time, 'HH:mm');
            const now = moment();
            const diff = endTime.diff(now);
            reloadCondition(diff);
        });
        let fnSetTimeout = null;
        const reloadCondition = (time) => {
            clearTimeout(fnSetTimeout);
            fnSetTimeout = setTimeout(() => {
                getRoomList();
                getMemberWorkStatus();
            }, time + 60000);
        };
        onUnmounted(()=>{
            clearTimeout(fnSetTimeout);
        });

        // 服務中待班中的編輯
        const memberList = reactive({
            isShow: false,
            type: ""
        });
        watch(
            () => memberList.isShow,
            (isShow) => {
                if (!isShow) {
                    memberList.type = "";
                    getMemberWorkStatus();
                }
            }
        );

        const changeAroma = (val, id) => {
            memberWorkList.data.reserve_list.forEach(item => {
                item.reserve_customer.forEach(v => {
                    if(v.reserve_detail_id === id) {
                        v.loading = true;
                    }
                })
            })
            setReserveMember(val, id);
        }

        const setReserveMember = async (member_id, reserve_detail_id) => {
            try {
                const res = await basePost('/api_reserve/set_reserve_detail_member_id', {
                    member_id: member_id || '',
                    reserve_detail_id
                });
                const { status, data } = res
                if (status === 200 && data.res_code === 1) {
                    getMemberWorkStatus()
                }
            } catch (err) {
                console.error(err);
            }
        };

        // 服務中待班中的編輯
        const appointment = reactive({
            isShow: false,
            id: "",
            name: "",
            currentEndTime: ""
        });
        const openAppointment = (item, type ) => {
            appointment.isShow = true;
            appointment.id = item.member_id
            appointment.name = item.member_name
            if(type === 'service') {
                appointment.currentEndTime =  item.end_time
            }
        }
        watch(
            ()=> appointment.isShow, 
            (val) => {
                if(!val) {
                    appointment.id = "";
                    appointment.name = "";
                    appointment.currentEndTime = "";
                }
            }
        )


        // 打卡紀錄
        let rosterRecord = reactive({ data: [] });
        // 取得打卡紀錄列表 給下拉用
        const getRosterRecord = async () => {
            try {
                const res = await basePost("/api_member/member_punch_list", {
                    store_id: state.auth.store_id,
                    date: moment().format('YYYY-MM-DD')
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    const { res_data } = data;
                    rosterRecord.data = res_data;
                }
            } catch (err) {
                console.error(err);
            }
        };

        return {
            roomList,
            memberWorkList,
            passId2CheckoutLightbox,
            checkout,
            isListRequest,
            cutInLine,
            changeTime,
            memberList,
            changeAroma,
            appointment,
            openAppointment,
            rosterRecord
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '~@/css/mixins';
@import '~@/css/grid';
.on-site-room {
    .index-container {
        padding-right: 0 !important;
        .order-list-wrap {
            .col-xxl-3 {
                padding: 0 6px;
            }
        }
        .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            color: #fff;
            margin-left: 50px;
            margin-bottom: 30px;
            position: relative;
            &::before {
                position: absolute;
                content: '';
                top: 0;
                bottom: 0;
                left: -15px;
                border-left: 7px solid #fff;
            }
        }
        .add-room {
            position: fixed;
            right: 27%;
            bottom: 40px;
            width: unset;
            z-index: 1;
            @media (max-width: 1800px) {
                right: 35%;
            }
            @media (max-width: 1366px) {
                right: 37%;
            }
            @media (max-width: 1200px) {
                right: 55px;
                bottom: 30px;
            }
            .img-wrap {
                background: #000 0% 0% no-repeat padding-box;
                box-shadow: 0px 18px 31px #45425359;
                border: 2px solid #ffffffd1;
                width: 54px;
                height: 54px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                @media (max-width: 1400px) {
                    width: 45px;
                    height: 45px;
                }
            }
        }
        .order-title {
            color: #a4641a;
            font-size: 17px;
            font-weight: bold;
            position: relative;
            padding-left: 15px;
            display: none;
            z-index: 1;
            top: 40px;
            left: 30px;
            &:before {
                position: absolute;
                content: '';
                top: -2px;
                bottom: -2px;
                left: 0;
                border: 3px solid #a4641a;
            }
            @media (max-width: 1400px) {
                display: block;
            }
        }
        .order-list-wrap,
        .staff-show-wrap {
            margin-bottom: 15px;
            box-shadow: 0px 34px 77px #00000042;
            border: 1px solid #fff;
            border-radius: 15px;
            filter: contrast(100%);
            transition: all 0.3s;
            &:hover {
                filter: contrast(105%);
            }
        }
        .order-list-wrap {
            background: transparent linear-gradient(122deg, #fff 0%, #d9d9d9 18%, #fff 100%) 0% 0% no-repeat padding-box;
            padding: 70px 0;
            padding-left: 80px;
            padding-right: 100px;
            overflow: overlay;
            position: relative;
            margin-left: 0;
            margin-right: 0;
            height: 1056px;
            @media (max-width: 1800px) {
                padding: 70px 80px 70px 60px;
            }
            @media (max-width: 1400px) {
                padding: 60px 80px 30px 30px;
            }
            &::-webkit-scrollbar-track {
                background-color: #d6d6d6;
                border-radius: 2px;
            }
            &::-webkit-scrollbar {
                width: 7px;
            }
            &::-webkit-scrollbar-thumb {
                background: #707070;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 9px;
            }

            .room-item {
                background: #f2f2f2f2 0% 0% no-repeat padding-box;
                box-shadow: 0px 8px 9px #00000024;
                border: 1px solid #ffffff8c;
                border-radius: 11px;
                color: #000;
                @include hover(95%);
                .room-title {
                    padding-top: 30px;
                    padding-bottom: 10px;
                    padding-left: 20px;
                    padding-right: 20px;
                    font: normal normal 600 18px/24px Open Sans;
                    letter-spacing: 0px;
                    border-bottom: 1px solid #ddd;
                    display: flex;
                    justify-content: space-between;
                }
                .room-body {
                    padding: 20px;
                    padding-top: 15px;
                    padding-bottom: 25px;
                    font: normal normal 600 16px/22px Open Sans;
                    letter-spacing: 0px;
                    .img-wrap {
                        padding-right: 5px;
                    }
                    .btn {
                        padding: 5px 8px;
                        max-width: 100px;
                        min-width: 66px;
                        min-height: 26px;
                        font: normal normal bold 16px/21px Microsoft JhengHei;
                        letter-spacing: -0.16px;
                        color: #fff;
                        border-radius: 7px;
                        background: rgba(0, 0, 0, 0.4);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: auto;
                    }
                    .btn-gold {
                        background: transparent
                            linear-gradient(180deg, #ebc496 0%, #a4641a 36%, #b57831 88%, #a4641a 100%) 0% 0% no-repeat
                            padding-box;
                        box-shadow: 0px 3px 6px #00000029;
                        cursor: pointer;
                        @include hover(130%);
                    }
                }
            }
            .room-reserve {
                background: #ccc 0% 0% no-repeat padding-box;
                .time-color {
                    color: #707070;
                }
            }
            .room-active {
                background: #d67675 0% 0% no-repeat padding-box;
                color: #fff;
                position: relative;
                .active-icon-wrap {
                    position: absolute;
                    top: -18px;
                    left: 15px;
                }
                .time-color {
                    color: #fff;
                }
            }
        }
        .staff-show-wrap {
            background: transparent linear-gradient(148deg, #fff 0%, #e5e5e5 18%, #fff 100%) 0% 0% no-repeat padding-box;
            padding-top: 10px;
        }
        .staff-show-wrap {
            .staff-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font: normal normal bold 16px/21px Microsoft JhengHei;
                letter-spacing: -0.16px;
                color: #4a4a4a;
                padding: 20px 10px 5px;
                position: relative;
                height: 67px;
                &:after {
                    position: absolute;
                    content: '';
                    border-bottom: 1px solid #4a4a4a;
                    right: 10px;
                    left: 10px;
                    bottom: 0;
                }
                .staff-title {
                    font-size: 20px;
                    color: #a4641a;
                    display: flex;
                    align-items: flex-end;
                    font-weight: bold;
                    flex-grow: 1;
                    span {
                        line-height: 22px;
                        padding-left: 10px;
                    }
                    .hint {
                        font-size: 16px;
                    }
                }
                button.edit-btn {
                    position: absolute;
                    right: 35px;
                    top: -5px;
                    color: #fff;
                    background: transparent linear-gradient(180deg, #ebc496 0%, #a4641a 36%, #b57831 88%, #a4641a 100%)
                        0% 0% no-repeat padding-box;
                    border: 0;
                    border-radius: 11px;
                    padding: 5px 8px;
                    margin-left: 10px;
                }
            }
            .staff-body {
                height: 260px;
                padding: 0;
                margin: 20px 10px;
                .text-wrap {
                    height: 100%;
                    overflow-y: auto;
                    &::-webkit-scrollbar-track {
                        background-color: #d6d6d6;
                        border-radius: 2px;
                    }
                    &::-webkit-scrollbar {
                        width: 7px;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: #707070;
                        box-shadow: 0px 3px 6px #00000029;
                        border-radius: 9px;
                    }
                }
                p {
                    display: flex;
                    justify-content: space-between;
                    padding-left: 10px;
                    padding-right: 3px;
                    font: normal normal 600 16px/22px Open Sans;
                    letter-spacing: 0px;
                    color: #000;
                    padding-bottom: 10px;
                    .name {
                        color: #4a4a4a;
                        width: 100%;
                        .btn-link {
                            font-weight: 600;
                            color: #4a4a4a;
                            line-height: 1;
                            text-decoration: none;
                            &.yellow {
                                color: #d5a240;
                            }
                        }
                        span {
                            font-weight: normal;
                            color: #A2A2A2;
                        }
                    }
                }
            }
            .before-date,
            .next-status,
            .end-time,
            .hour-time,
            .sign-in-8 {
                text-align: center;
                flex-shrink: 0;
            }
            .before-date {
                width: 70px;
            }
            .next-status {
                width: 55px;
                text-align: center;
                &.translate-t-2{
                    transform: translateY(-2px);
                }
                p {
                    padding: 2px 5px;
                    border-radius: 25px;
                }
            }
            .end-time {
                width: 70px;
            }
            .hour-time {
                width: 40px;
            }
            .sign-in-8 {
                width: 40px;
                .icon-check:before {
                    color: #000;
                    font-size: 12px;
                }
            }
            .reserve-people,
            .reserve-date,
            .reserve-aroma {
                text-align: center;
                flex-shrink: 0;
            }
            .reserve-people {
                width: 40px;
            }
            .reserve-date {
                width: 70px;
            }
            .reserve-aroma {
                width:165px;
            }
            .aroma-list {
                padding: 0px;
                width: 50px;
                background: transparent;
                text-align: center;
                margin: 0 5px 5px 0;
                border-radius: 25px;
                border-color: transparent;
                option {
                    background-color: #fff
                }
                &.aroma-wait {
                    color: transparent;
                }
            }

            .img-wrap {
                display: inline-block;
                height: 23px;
                width: 28px;
                vertical-align: middle; 
                a {
                    display: inline-block;
                    padding-right: 5px;
                    width: 28px;
                    height: 23px;
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
    .room-wrap,
    .side-info-wrap {
        flex: 0 0 auto;
        padding-right: 15px;
        padding-left: 15px;
    }
    .room-wrap  {
        width: 75%;
        @media (max-width: 1800px) {
            width: calc(100% / 3 * 2);
        }
        @media (max-width: 1200px) {
            width: 100%;
        }
    }
    .side-info-wrap {
        width: 25%;
        @media (max-width: 1800px) {
            width: calc(100% / 3);
        }
        @media (max-width: 1200px) {
            width: 100%;
        }
    }
    .btn-icon {
        @include size(20px);
        border-radius: 50px;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        background: linear-gradient(180deg, rgba(70, 70, 70, 0.6), rgba(74, 74, 74, 0.6));
        color: #fff;
    }
}
</style>
